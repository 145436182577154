<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="transfers"
      :loading="isLoadingTransfers"
      :server-items-length="transfersMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('transfer-update')"
          class="mr-2"
          color="#C6C6C6"
          @click="editTransfer(item)"
        >
          $vuetify.icons.iconEditItem
        </v-icon>
        <v-icon
          class="mr-2"
          v-if="$admin.can('transfer-delete')"
          @click="deleteItem(item)"
        >
          $vuetify.icons.iconDeleteItem
        </v-icon>
      </template>
      <template v-slot:top>
        <v-row class="my-2">
          <v-col cols="12" sm="2" md="2" class="pl-0">
            <v-text-field
              hide-details
              outlined
              clearable
              dense
              v-model="filters.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="2" md="2" class="pl-0">
            <v-autocomplete
              outlined
              clearable
              dense
              hide-details
              v-model="filters.from_hub_id"
              :items="fromHubsList"
              item-text="name"
              item-value="id"
              :label="$t('origin_hub')"
              :search-input.sync="search_from_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="pl-0">
            <v-autocomplete
              outlined
              clearable
              dense
              hide-details
              v-model="filters.to_hub_id"
              :items="toHubsList"
              item-text="name"
              item-value="id"
              :label="$t('destination_hub')"
              :search-input.sync="search_to_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" class="px-0">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  hide-details
                  v-model="filters.created_date_range"
                  :label="$t('created_date_range')"
                  append-icon="mdi-calendar"
                  readonly
                  chips
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.created_date_range"
                range
                no-title
                color="primary"
                class="my-0 py-4"
                header-color="primary"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
    </v-data-table>

    <delete-transfer-modal
      v-if="$admin.can('transfer-delete') && isDeleteModalOpen"
      :isDeleteModalOpen="isDeleteModalOpen"
      :transfer="currentTransfer"
      :toggleDeleteModal="() => toggleDeleteModal()"
    ></delete-transfer-modal>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import hubService from "@/store/services/hub-service";

export default {
  components: {
    DeleteTransferModal: () => import("../modals/DeleteTransferModal.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingTransfers: "transferProducts/isLoadingTransfers",
      transfers: "transferProducts/list",
      transfersMeta: "transferProducts/meta",
      hubs: "hubs/activeHubs",
    }),

    filters: {
      get() {
        return this.$store.state.transferProducts.filters;
      },
      set(value) {
        this.$store.commit("transferProducts/SET_FILTERS", value);
      },
    },
  },
  props: {
    isActive: {
      types: Boolean,
    },
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          await this.$store
            .dispatch("transferProducts/list", {
              ...params,
              ...this.filters,
            })
            .then(() => {})
            .catch(() => {
              this.$store.dispatch("alerts/error", "Transfer not found");
            });
        }
      }, 1000),
      deep: true,
    },
    filters: {
      handler: debounce(async function (params) {
        if (this.isActive) {
          await this.$store
            .dispatch("transferProducts/list", {
              ...params,
              ...this.options,
            })
            .then(() => {})
            .catch(() => {
              this.$store.dispatch("alerts/error", "Transfer not found");
            });
        }
      }, 1000),
      deep: true,
    },

    search_to_hub: debounce(async function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;
      this.toHubsList = (await hubService.list({ search })).hubs;
      this.is_loading_hubs = false;
    }, 1000),

    search_from_hub: debounce(async function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;
      this.fromHubsList = (await hubService.list({ search })).hubs;
      this.is_loading_hubs = false;
    }, 1000),
  },

  mounted: async function () {
    this.fromHubsList = this.toHubsList = (await hubService.list()).hubs;
  },

  data() {
    return {
      search_to_hub: "",
      search_from_hub: "",
      toHubsList: [],
      fromHubsList: [],
      is_loading_inbounds: false,
      is_loading_hubs: false,
      options: {},
      index: 0,
      is_loading: false,
      is_loading_invoice: false,
      is_loading_dn: false,
      order_date_start: null,
      order_date_end: null,
      isDeleteModalOpen: false,
      currentTransfer: null,

      headers: [
        {
          text: this.$t("from_hub"),
          align: "start",
          sortable: false,
          value: "fromHub.name",
        },
        {
          text: this.$t("to_hub"),
          align: "start",
          sortable: false,
          value: "toHub.name",
        },
        {
          text: this.$t("products_count"),
          align: "center",
          sortable: false,
          value: "products_count",
        },
        {
          text: this.$t("creation_date"),
          align: "center",
          sortable: true,
          value: "created_at",
        },

        {
          text: this.$t("actions"),
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    editTransfer(item) {
      this.$router.push({
        name: "transfers.edit.draft",
        params: { id: item.id },
      });
    },

    toggleDeleteModal() {
      this.isDeleteModalOpen = !this.isDeleteModalOpen;
    },

    deleteItem(item) {
      console.log(item);
      this.isDeleteModalOpen = true;
      this.currentTransfer = item;
    },
  },
};
</script>
